import { environment, scopeProctedURLs } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SidenavComponent } from './sidenav/sidenav.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ProtocolMode } from '@azure/msal-common';
import {
  MsalModule,
  MsalService,
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import {
  LogLevel,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UpdateSnackBarComponent } from './update-snack-bar/update-snack-bar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DrawerItemComponent } from './drawer-item/drawer-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DrawerComponent } from './drawer/drawer.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AclModule } from './acl/acl.module';
import { MonitoringService } from './common/monitoring.service';
import { ErrorHandlerService } from './common/error-handler.service';
import { NgxEchartsModule } from 'ngx-echarts';
import { OIDC_DEFAULT_SCOPES, UrlString, Constants } from "@azure/msal-common";

OIDC_DEFAULT_SCOPES.splice(OIDC_DEFAULT_SCOPES.indexOf('offline_access'), 1);

UrlString.parseHash = function(hashString: string): string {
        const hashIndex1 = hashString.indexOf("#");
        const hashIndex2 = hashString.indexOf("#/");
        const hashIndex3 = hashString.indexOf("#?code=");
        if (hashIndex3 > -1) {
            return hashString.substring(2);
        }else if (hashIndex2 > -1) {
            return hashString.substring(hashIndex2 + 2);
        } else if (hashIndex1 > -1) {
            return hashString.substring(hashIndex1 + 1);
        }
        return Constants.EMPTY_STRING;
    };

export function loggerCallback(logLevel, message) {
    console.log(message);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,
    UserMenuComponent,
    UpdateSnackBarComponent,
    DrawerItemComponent,
    DrawerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    FormsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatExpansionModule,
    MatMenuModule,
    MatTooltipModule,
    AclModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.settings.idp.client_id,
          authority: environment.settings.idp.auth_url,
          knownAuthorities: environment.settings.idp.known_authorities,
          authorityMetadata: environment.settings.idp.auth_metadata,
          redirectUri: environment.settings.idp.redirect_uri,
          postLogoutRedirectUri: window.location.origin,
          protocolMode: ProtocolMode.OIDC,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        system: {
          loggerOptions: {
            loggerCallback,
            piiLoggingEnabled: true,
            logLevel: LogLevel.Verbose
          }
        }
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
              scopes: environment.settings.idp.scope.split(' ')
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration,
        protectedResourceMap: new Map(scopeProctedURLs()),
      }
    ),
    SweetAlert2Module.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },

    { provide: MonitoringService, useClass: MonitoringService },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],

  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
